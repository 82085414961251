import axios from 'axios';
export const payInvoices = async (parameters) => {
    return axios.post('/api/v1/invoices/pay', {
        ...parameters
    }).then(response => {
        return response;
    });
};
export const downloadInvoices = async (parameters) => {
    return axios.get('/api/v1/invoices/download', {
        params: {
            ...parameters
        },
        headers: {
            'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
    }).then(response => {
        return response;
    });
};
export const getInvoices = async (filters) => {
    return axios.get('/api/v1/florisoft_invoices', {
        params: {
            ...filters
        }
    }).then(response => {
        return response;
    });
};
export const payMultipleInvoices = async (invoicesChecked, currentAccount) => {
    return axios.post('/api/v1/invoices/multiple_pay', {
        invoice_numbers: invoicesChecked,
        account: currentAccount
    }).then(response => {
        return response;
    });
};
