import React from 'react';

const ArrowDownSvg = (props) => {
    return(
    <>
    <svg xmlns="http://www.w3.org/2000/svg" width="8.153" height="4.522" viewBox="0 0 8.153 4.522">
        <defs>
            <style>
                {`.a{fill:none;stroke:${props.color};stroke-linecap:round;stroke-miterlimit:10;}`}
            </style>
            </defs>
            <path class="a" d="M828.025,1156.461l-3.2,3.2a.24.24,0,0,1-.34,0l-3.2-3.2" transform="translate(-820.579 -1155.754)"/>
    </svg>
    </>
    )  
}

export default ArrowDownSvg;